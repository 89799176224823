import React from 'react';
import PageNotFoundOrUserNotAuthorized from '../components/404';
import Layout from '../components/layout';

function NotFound() {
    return (
        <Layout title="404">
            <PageNotFoundOrUserNotAuthorized />
        </Layout>
    );
}

export default NotFound;
