import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppContext } from '../contexts/appContext';

function PageNotFoundOrUserNotAuthorized() {
    const { user } = useAppContext();
    const [countDown, setCountDown] = useState(5);
    const intervalKey = useRef<NodeJS.Timer>();
    const router = useRouter();
    const interval = useCallback(
        () =>
            setInterval(() => {
                setCountDown(current => current - 1);
            }, 900),
        [],
    );
    useEffect(() => {
        if (!user || !user.groups) return;
        intervalKey.current = interval();
        // eslint-disable-next-line consistent-return
        return () => {
            clearInterval(intervalKey.current);
        };
    }, [interval, user]);

    useEffect(() => {
        if (countDown === 0) {
            clearInterval(intervalKey.current);
            router.push('/');
        }
    }, [countDown, router]);

    return (
        <div className="flex justify-center items-center text-center h-screen">
            {!user || !user.groups ? (
                <p className="w-96 text-gray-300">loading user data</p>
            ) : (
                <p className="w-96 text-gray-300">
                    The page you&apos;re looking for either doesn&apos;t exist or you don&apos;t
                    have access to view. Redirecting to home in {countDown} seconds...
                </p>
            )}
        </div>
    );
}

export default PageNotFoundOrUserNotAuthorized;
